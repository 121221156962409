import { render, staticRenderFns } from "./productPendingList.vue?vue&type=template&id=0cb359d4&scoped=true&lang=html&"
import script from "./productPendingList.vue?vue&type=script&lang=js&"
export * from "./productPendingList.vue?vue&type=script&lang=js&"
import style0 from "./productPendingList.vue?vue&type=style&index=0&id=0cb359d4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb359d4",
  null
  
)

export default component.exports